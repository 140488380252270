.graphical-record-container {
  padding: 0.5rem 0;
  max-width: 100%;
}

.filter-buttons {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.filter-button {
  padding: 0.5rem 1rem;
  border: 1px solid #007bff;
  border-radius: 5px;
  background-color: white;
  color: #007bff;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.filter-button:hover {
  background-color: #007bff;
  color: white;
}

.filter-button.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #0056b3;
}

.chart-container {
  height: 400px;
}

@media (max-width: 768px) {
  .filter-buttons {
    flex-wrap: wrap;
  }

  .filter-button {
    margin-bottom: 0.5rem;
  }

  .chart-container {
    height: 300px;
  }
}

.custom-range {
  display: flex;
  gap: 0.5rem;
}
.custom-range input {
  display: block;
  width: 100%;
  max-width: 300px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  @media(max-width:500px){
    font-size: 12px;
  }
}

.custom-range input:focus {
  border-color: #4caf50; 
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5); 
  outline: none;
}
.no-record-message {
  display: flex;
  height: 5rem;
  justify-content: center;
  align-items: center;
}
