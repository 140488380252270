.uppy-uploader-container  .uppy-StatusBar-actionBtn--done {
    display: none;
}

.uppy-Dashboard-AddFiles-info {
    display: none !important;
}

.uppy-Dashboard-AddFiles {
    border: 1px dashed #92D4E9 !important;
}

.uppy-Dashboard-inner {
    background-color: #EBF7FB;
}