.react-calendar {
    width: 290px;
    background-color: #EBF7FB;
    color: #000000;
    border-radius: 12px;
    border: 0;
    outline: none;
}

.react-calendar__navigation button {
    background-color: #2FAED7;
    font-size: 13px;
    color: #FFFFFF;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}


.react-calendar__navigation {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    overflow: hidden;
}

.react-calendar .react-calendar__navigation button:hover,
.react-calendar .react-calendar__navigation button:active,
.react-calendar .react-calendar__month-view__days__day:hover,
.react-calendar .react-calendar__month-view__days__day:active {
    background-color: #0099CC !important;
    color: #FFFFFF !important;
}

.react-calendar__navigation button:hover,
.react-calendar__navigation button:active {
    background-color: #0099CC !important;
    color: #FFFFFF !important;
}