
.urgency-tag {
    padding: 4px 8px;
    border-radius: 12px;
    color: white;
    font-weight: bold;
  }
  
  .urgency-tag.high {
    background-color: red;
  }
  
  .urgency-tag.medium {
    background-color: orange;
  }
  
  .urgency-tag.low {
    background-color: green;
  }
  