body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  header {
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2.5%;
  }
  
  .header__title {
    font-size: 35px;
    display: flex;
    /* margin-bottom: .20em; */
  }
  
  .header__sub-title {
    font-size: 18px;
    font-weight: 200;
  }
  
  .container {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .sound-wave {
    /* Define your styles for the sound wave visualization */
    width: 100%;
    /* Add other styling as needed */
  }
  /* 
  .real-time-interface {
    margin: 1em 0;
  } */
  
  .real-time-interface__button {
    display: flex;
    padding: .5em;
    border-radius: .5rem;
    justify-content: center;
    cursor: pointer;
    background-color: #7ABA56;
    color: white;
    width: 200px; /* Fixed width */
    font-size: 18px;
    border: 2px solid #7ABA56;
  }
  
  .real-time-interface__title {
    margin-bottom: .5em;
    font-size: 12px;
    font-weight: 325;
    text-align: left;
  }
  
  .real-time-interface__message {
    text-align: left;
    width: 90%; /* Fixed width */
    max-width: 1000px; /* Ensures the width doesn't exceed 400px */
    height: 500px; /* Fixed height */
    max-height: 500px; /* Ensures the height doesn't exceed 200px */
    margin-top: .5em;
    margin-bottom: .5em;
    font-size: 19px;
    font-weight: 400;
    box-shadow: 0 0 32px 0 rgb(0 0 0 / 16%);
    padding: 1em;
    transition: .5s ease-in;
    background-color: #fff;
    border-color: #0099CC;
    border-width: 10px;
    border-radius: 5px;
    border: 2px solid #7ABA56;
    color: #000;
    overflow: auto; /* Add scrollbars if content overflows */
    word-wrap: break-word; /* Ensures long words are wrapped */
  }
  
  .para{
    color: #000;
  }
  
  .audio {
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .header__title {
      font-size: 1.5em;
    }
  
    .real-time-interface__button {
      padding: 8px 16px;
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 480px) {
    .header__title {
      font-size: 1.2em;
    }
  
    .real-time-interface__button {
      padding: 6px 12px;
      font-size: 0.8em;
    }
  }


